import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next"; // Importing useTranslation hook
import { Home } from "./pages/Home/home.page";
import { Navbar } from "./components/Navbar/navbar.component";
import { ProductPageWrapper, Shop } from "./pages/Shop/shop.page";
import { ShopNavbar } from "./components/ShopNavbar/shopNavbar.component";
import { Newsletter } from "./pages/Newsletter/Newsletter.component";
import { LetMeGo } from "./pages/LandingPages/LetMeGo/LetMeGo.page";
import { Adbar } from "./components/Adbar/Adbar.component";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState<string>();

  useEffect(() => {
    // Check if the pathname is just '/' or effectively empty
    if (location.pathname === "/") {
      navigate("/en/");
    }
  }, [navigate, location.pathname]);
  const { i18n } = useTranslation(); // Using i18next in the component
  const supportedLanguages =
    process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",");

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    // Determine the position of the language code based on the number of segments in the URL
    const potentialLang = pathParts[1];
    if (potentialLang && supportedLanguages?.includes(potentialLang)) {
      i18n.changeLanguage(potentialLang);
    }
    setCurrentLang(potentialLang);
  }, [location, i18n]);

  const renderNavbar = () => {
    const pathParts = location.pathname.split("/");
    if (pathParts[1] === "shop") {
      return <ShopNavbar />;
    } else if (pathParts[2] === "let-me-go") {
      return <Adbar />;
    } else {
      return <Navbar />;
    }
  };

  return (
    <div>
      {renderNavbar()}
      <Routes>
        <Route path=":lang/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:productId" element={<ProductPageWrapper />} />
        <Route path=":lang/newsletter/" element={<Newsletter />} />
        <Route path="/newsletter" element={<Newsletter />} />
        <Route
          path=":lang/let-me-go/"
          element={<LetMeGo currentLang={currentLang} />}
        />
        <Route
          path="/let-me-go"
          element={<LetMeGo currentLang={currentLang} />}
        />
        {/* Fallback to default language */}
      </Routes>
    </div>
  );
}

export default App;
