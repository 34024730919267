import React from "react";
import styles from "./footer.module.scss";
import { SignupForm } from "../SignupForm/SignupForm.component";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footer}>
      <h2>{t("single_out_now")}</h2>
      <p>© 2024 INDIECLIMB RECORDS</p>
    </div>
  );
};
