// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    debug: false,
    ns: ["translatios"],
    defaultNS: "translations",
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      requestOptions: { mode: "no-cors" }, // Example of additional option
      crossDomain: true,
    },
  });

export default i18n;
