import React from "react";
import styles from "./shopItem.module.scss";

export const ShopItem: React.FC<{
  image: string;
  name: string;
  id: string;
  description: string;
  sizes: { size: string; quantity: number }[];
}> = ({ image, name, id, description }) => {
  return (
    <div className={styles.shopItem}>
      <div className={styles.image}>
        <img src={image} alt={description} />
      </div>
      <h3>{name}</h3>
    </div>
  );
};
