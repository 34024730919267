import React from "react";
import styles from "./productThumbnails.module.scss";
import { Link } from "react-router-dom";

interface Product {
  id: string;
  name: string;
  description: string;
  image: string;
  price: number;
}

interface ProductThumbnailsProps {
  thumbnails: Product[];
  activeThumbnailIndex: number;
  onClickThumbnail: (index: number) => void;
}

export const ProductThumbnails: React.FC<ProductThumbnailsProps> = ({
  thumbnails,
  activeThumbnailIndex,
  onClickThumbnail,
}) => {
  return (
    <div className={styles.productThumbnails}>
      {thumbnails.map((thumbnail: Product, index: number) => (
        <Link to={`/shop/${thumbnail.id}`} key={thumbnail.id}>
          <div
            className={`${styles.thumbnail} ${
              index === activeThumbnailIndex ? styles.active : ""
            }`}
            onClick={() => onClickThumbnail(index)}
          >
            <img src={thumbnail.image} alt={thumbnail.name} />
          </div>
        </Link>
      ))}
    </div>
  );
};
