import React, { useEffect } from "react";
import styles from "./LetMeGo.module.scss";

import { ScrollyTellImage } from "../../../components/ScrollyTellImage/ScrollyTellImage.component";
import { FullscreenText } from "../../../components/FullscreenText/FullscreenText.component";
import { QuoteScreen } from "../../../components/QuoteScreen/QuoteScreen.component";
import { Actionscreen } from "../../../components/ActionScreen/Actionscreen.component";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollLine from "../../../components/ScrollLine/ScrollLine.component";
const supportedLanguages =
  process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(",");

export const LetMeGo: React.FC<{ currentLang: any }> = ({ currentLang }) => {
  const pixelCode = `

  <!-- Meta Pixel Code -->
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '7585404441481822');
  fbq('track', 'PageView');
;
<!-- End Meta Pixel Code -->`;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the pathname is just '/' or effectively empty
    if (location.pathname === "/let-me-go") {
      navigate("/en/let-me-go");
    }
  }, [navigate, location.pathname]);

  function handleImageError(
    event: React.SyntheticEvent<HTMLImageElement>,
    defaultImage: string
  ) {
    event.currentTarget.src = `/images/Awards/${defaultImage}`;
  }

  return (
    <div className={styles.letMeGoPage}>
      <Helmet>
        <title>Indieclimb - LET ME GO</title>
        <meta name="robots" content="noindex" />
        <script>{pixelCode}</script>
      </Helmet>
      <ScrollLine />
      <ScrollyTellImage
        svh={true}
        imgUrl="/images/Scrollytell/LET ME GO – 4K.00_01_06_16.Still084 copy (1).jpg"
        positionHorizontal={40}
        washedOut
        fadein={false}
      >
        <div className={styles.topBanner}>
          <div className={styles.titleName}>
            <div className={styles.center}>
              <p>{t("directed_and_produced_by")}</p>
              <h1>{t("let_me_go")}</h1>
            </div>
          </div>
          <div className={styles.awards}>
            <img
              style={{ opacity: 0, transition: "opacity 1s" }}
              onLoad={(e) => {
                e.currentTarget.style.opacity = "1";
              }}
              src={`/images/Awards/${currentLang}_roma.png`}
              alt="Award"
              onError={(e) => handleImageError(e, "en_roma.png")}
            />
            <img
              style={{ opacity: 0, transition: "opacity 1s" }}
              onLoad={(e) => {
                e.currentTarget.style.opacity = "1";
              }}
              src={`/images/Awards/${currentLang}_budapest.png`}
              alt="Award"
              onError={(e) => handleImageError(e, "en_budapest.png")}
            />
          </div>
          <div
            className={styles.scrollDown}
            onClick={() => {
              window.scrollBy({
                top: window.innerHeight, // Vertical scroll increments by the height of the viewport
                left: 0, // Horizontal scroll increments by 100 pixels
                behavior: "smooth", // Smoothly animate the scroll
              });
            }}
          >
            <p>{t("scroll_for_more")}</p>
            <img
              src="https://www.seekpng.com/png/full/241-2418356_arrow-white-white-scroll-down-gif.png"
              alt=""
            />
          </div>
        </div>
      </ScrollyTellImage>

      <FullscreenText text={t("debut_single_info")} />

      <ScrollyTellImage
        imgUrl="/images/Scrollytell/2LET ME GO – 4K.04_44_23_01.Still235 copy.jpg"
        panorama
      />

      <FullscreenText text={t("international_recognition")} />

      <ScrollyTellImage
        imgUrl="/images/Scrollytell/LET ME GO – 4K.04_10_45_15.Still246 copy.jpg"
        panorama
        position="center"
      />

      <FullscreenText text={t("artist_announcement")} />

      <ScrollyTellImage
        imgUrl="/images/Scrollytell/LET ME GO – 4K.04_21_33_14.Still250 copy.jpg"
        panorama
        position="bottom"
      />

      <FullscreenText text={t("outro")} />

      <ScrollyTellImage imgUrl="/images/Scrollytell/ida_car.jpg" washedOut>
        <QuoteScreen
          header={t("what_the_critics_say")}
          quotes={[
            {
              author: "Nors Kode",
              quote: t("a_beautiful_listening_experience"),
            },
            {
              author: "Dalfson",
              quote: t("a_truly_euphoric_and_uplifting_piece_of_art"),
            },
            {
              author: "Tasteofelectro",
              quote: t("new_genre_unlocked_cinematic_techno"),
            },
          ]}
        />
      </ScrollyTellImage>
      <ScrollyTellImage
        imgUrl="/images/Scrollytell/LET ME GO – 4K.04_21_50_05.Still252 copy.jpg"
        washedOut
        positionHorizontal={82}
        fadein={false}
      >
        <Actionscreen
          translations={{ watch: t("watch"), listen: t("listen_now") }}
        />
      </ScrollyTellImage>
    </div>
  );
};
