import React from "react";
import styles from "./ColorButton.module.scss";

export const ColorButton: React.FC<{
  link: string;
  text: string;
  isUppercase?: boolean;
}> = ({ isUppercase, link, text }) => {
  return (
    <div className={styles.colorButton}>
      <a href={link} className={isUppercase ? styles.uppercase : ""}>
        {text}
      </a>
    </div>
  );
};
