import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import App from "./App";

// Use the non-null assertion operator (!) to assert that the element is not null.
const container = document.getElementById("root")!;
const root = createRoot(container); // Now TypeScript knows `container` is not null.
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
