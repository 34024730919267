// Import necessary modules
import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { ProductPage } from "../ProductPage/productPage.page";
import { Link } from "react-router-dom";
import styles from "./shop.module.scss";
import { ShopItem } from "../../components/ShopItem/shopItem.component";

// Dummy data for shop items
const shopItems = {
  sweatshirt: [
    {
      id: "f47ac10b-58cc-4372-a567-0e02b2c3d479",
      name: "Blue sweatshirt",
      description: "Description for Item 1",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
        { size: "XL", quantity: 4 },
      ],
      image: "/images/photos/Sweatshirts/Indieclimb/indieclimb-blue.png",
    },
    {
      id: "d250e91b-98d2-4b57-9559-1897406b54d5",
      name: "White sweatshirt",
      description: "Description for Item 2",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 10 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
        { size: "XL", quantity: 4 },
      ],
      image: "/images/photos/Sweatshirts/Indieclimb/indieclimb-white.png",
    },
    {
      id: "ae0a4bf4-8b1f-4d0c-85d7-4291d365f7f8",
      name: "Black sweatshirt",
      description: "Description for Item 3",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
        { size: "XL", quantity: 4 },
      ],
      image: "/images/photos/Sweatshirts/Indieclimb/indieclimb-black.png",
    },
  ],
  tees: [
    {
      id: "6fd5508c-3501-4c97-9c4b-20b52a1c28de",
      name: "Blue T-shirt",
      description: "Description for Item 1",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
        { size: "XL", quantity: 4 },
      ],
      image: "/images/photos/t-shirts/Indieclimb/indieclimb-blue.png",
    },
    {
      id: "6af8c120-6f81-4594-9a4c-aaab706d5d2e",
      name: "White T-shirt",
      description: "Description for Item 2",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
        { size: "XL", quantity: 4 },
      ],
      image: "/images/photos/t-shirts/Indieclimb/indieclimb-white.png",
    },
    {
      id: "54a07c3d-6c0c-437a-8e54-c71b4954cc18",
      name: "Black T-shirt",
      description: "Description for Item 3",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
        { size: "XL", quantity: 4 },
      ],
      image: "/images/photos/t-shirts/Indieclimb/indieclimb-black.png",
    },
  ],
  sticker: [
    {
      id: "e2893ac4-51b6-4d42-bdb9-0d46a6e9b654",
      name: "Sticker 2",
      description: "Description for Item 5",
      price: 19.99,
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
      ],
      image: "/images/photos/Stickers/blue.png",
    },
    {
      id: "0ae6061f-8a89-4d3c-91d3-14cf8d1ab6d2",
      name: "Sticker 1",
      description: "Description for Item 4",
      sizes: [
        { size: "S", quantity: 1 },
        { size: "M", quantity: 2 },
        { size: "L", quantity: 3 },
      ],
      price: 119.99,
      image: "/images/photos/Stickers/black.png",
    },
  ],
  cds: [
    {
      id: "e2893ac4-51b6-4d42-bdb9-0ddd46a6e9b654",
      name: "LET ME GO - CD",
      description: "Description for Item 5",
      price: 19.99,
      sizes: [
        { size: "cd", quantity: 1 },
        { size: "vinyl", quantity: 2 },
      ],
      image: "/images/photos/Cds/let-me-go.png",
    },
    {
      id: "e2893ac4-51b6-4d42-bdb9-0ddd46a6e9b6d54",
      name: "LET ME GO - VINYL",
      description: "Description for Item 5",
      price: 19.99,
      sizes: [
        { size: "cd", quantity: 1 },
        { size: "vinyl", quantity: 2 },
      ],
      image: "/images/photos/Vinyls/let-me-go.png",
    },
  ],
};

// Define interfaces for type safety
export interface ShopItems {
  id: string;
  name: string;
  description: string;
  image: string;
  price: number;
  sizes: { size: string; quantity: number }[];
}

interface ShopCategory {
  [key: string]: ShopItems[];
}

// Shop component
export function Shop() {
  const renderItems = (items: ShopItems[]) => {
    return items.map((item: ShopItems) => (
      <li key={item.id}>
        {/* Link to the product page */}
        <Link to={`/shop/${item.id}`}>
          <ShopItem
            description={item.description}
            id={item.id}
            image={item.image}
            name={item.name}
            sizes={item.sizes}
          />
        </Link>
      </li>
    ));
  };
  return (
    <div className={styles.shop}>
      {renderItems(shopItems.cds)}
      {renderItems(shopItems.tees)}
      {renderItems(shopItems.sweatshirt)}
      {renderItems(shopItems.sticker)}
    </div>
  );
}
// Wrapper component to pass props to ProductPage
export function ProductPageWrapper() {
  // Get the productId from the URL
  const { productId } = useParams();

  // Initialize an array to hold all products of the selected category
  let products: ShopItems[] = [];

  // Iterate through each category in shopItems
  for (const category in shopItems) {
    if (Object.prototype.hasOwnProperty.call(shopItems, category)) {
      const categoryProducts = (shopItems as ShopCategory)[category];
      // Check if the current category has the productId
      const foundProduct = categoryProducts.find(
        (item: ShopItems) => item.id === productId
      );
      if (foundProduct) {
        // If productId is found in the category, set the products array to the categoryProducts
        products = categoryProducts;
        break; // If product found, break out of loop
      }
    }
  }

  // Check if productId is available and render ProductPage with product data if available
  return productId && products.length > 0 ? (
    <ProductPage productId={productId} products={products} />
  ) : null;
}
// Export Shop component
export default Shop;
