import React, { useEffect, useState } from "react";
import styles from "./Adbar.module.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Adbar = () => {
  const [active, setActive] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [active]);

  const handleStreamingServiceClick = (service: string) => {
    // Track the event with Meta Pixel
    if (window.fbq) {
      window.fbq("track", `Link to music ${service}`, {
        buttonName: "Link to Streaming service",
      });
    }
  };

  const handleNavbarClick = () => {
    // Track the event with Meta Pixel
    if (window.fbq) {
      window.fbq("track", "Open navbar", {
        buttonName: "Open navbar",
      });
    }
  };

  return (
    <div className={styles.adbar} onClick={handleNavbarClick}>
      <div
        className={`${styles.burger} ${active ? styles.active : ""}`}
        onClick={() => setActive(!active)}
      >
        <div className={styles.line1}></div>
        <div className={styles.line2}></div>
        <div className={styles.line3}></div>
      </div>
      <div className={`${styles.adbarInner} ${active ? styles.visible : ""}`}>
        <div className={styles.adbarInnerTop}>
          <p>{t("listen_now")}</p>
          <div className={styles.streamingPlatforms}>
            <Link
              target="_blank"
              to="https://open.spotify.com/artist/5wk5mLirrHPNbkvor0f5S2?si=dufI4WJPRs6s9KD8gJLhRQ"
              className={styles.streamingPlatform}
              onClick={() => {
                handleStreamingServiceClick("Spotify");
              }}
            >
              <div>
                <img src="/images/icons/Spotify.png" alt="" />
              </div>
              <div className={styles.externalIcon}>
                <img src="/images/icons/External.svg" alt="" />
              </div>
            </Link>

            <Link
              target="_blank"
              to="https://music.apple.com/no/artist/indieclimb/1273179739?l=nb"
              className={styles.streamingPlatform}
              onClick={() => {
                handleStreamingServiceClick("Apple Music");
              }}
            >
              <div>
                <img src="/images/icons/Apple.png" alt="" />
              </div>
              <div className={styles.externalIcon}>
                <img src="/images/icons/External.svg" alt="" />
              </div>
            </Link>
            <Link
              target="_blank"
              to="https://www.deezer.com/en/artist/13039421"
              className={styles.streamingPlatform}
              onClick={() => {
                handleStreamingServiceClick("Deezer");
              }}
            >
              <div>
                <img src="/images/icons/Deezer_1.png" alt="" />
              </div>
              <div className={styles.externalIcon}>
                <img src="/images/icons/External.svg" alt="" />
              </div>
            </Link>
            <Link
              target="_blank"
              to="https://www.youtube.com/@Indieclimb?sub_confirmation=1"
              className={styles.streamingPlatform}
              onClick={() => {
                handleStreamingServiceClick("Youtube");
              }}
            >
              <div>
                <img src="/images/icons/Youtube.png" alt="" />
              </div>
              <div className={styles.externalIcon}>
                <img src="/images/icons/External.svg" alt="" />
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.socialLinks}>
          <Link
            target="_blank"
            to="https://www.instagram.com/indieclimb"
            className={styles.profileImage}
            onClick={() => {
              handleStreamingServiceClick("Instagram image");
            }}
          >
            <img src="/logo copy.jpg" alt="" />
          </Link>
          <p>{t("follow")}</p>
          <div className={styles.socialsAndTitle}>
            <Link
              target="_blank"
              to="https://www.instagram.com/indieclimb"
              className={styles.socialLink}
              onClick={() => {
                handleStreamingServiceClick("Instagram");
              }}
            >
              <div>
                <img src="/images/icons/instagram.svg" alt="" />
              </div>
            </Link>
            <Link
              target="_blank"
              to="https://www.facebook.com/Indieclimb"
              className={styles.socialLink}
              onClick={() => {
                handleStreamingServiceClick("Facebook");
              }}
            >
              <div>
                <img src="/images/icons/facebook.svg" alt="" />
              </div>
            </Link>
            <Link
              target="_blank"
              to="https://www.tiktok.com/@indieclimb?_t=8lW96McvCnx&_r=1"
              className={styles.socialLink}
              onClick={() => {
                handleStreamingServiceClick("Tiktok");
              }}
            >
              <div>
                <img src="/images/icons/tiktok.svg" alt="" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
