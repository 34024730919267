import React, { useState } from "react";
import styles from "./productPage.module.scss";
import { ColorButton } from "../../components/Buttons/ColorButton/ColorButton.component";
import { ProductThumbnails } from "../../components/ProductThumbnails/productThumbnails.component";
import { Link } from "react-router-dom";
import { ProductSizes } from "../../components/ProductSizes/productSizes.component";

interface Product {
  id: string;
  name: string;
  description: string;
  image: string;
  price: number;
  sizes: { size: string; quantity: number }[];
}

interface ProductPageProps {
  productId: string;
  products: Product[];
}

export const ProductPage: React.FC<ProductPageProps> = ({
  productId,
  products,
}) => {
  // Find the selected product by productId
  const product = products.find((product) => product.id === productId);
  const productIndex = products.findIndex(
    (product) => product.id === productId
  );
  const [activeThumbnailIndex, setActiveThumbnailIndex] =
    useState(productIndex);
  const [activeSizeIndex, setActiveSizeIndex] = useState(0); // Initialize active size index

  const [selectedQuantity, setSelectedQuantity] = useState(1);

  // If product data is not available, display a loading message
  if (!product) {
    return <div>Loading...</div>;
  }

  // Destructure product data
  const { name, description, image, price, sizes } = product;

  const loopThroughQuantity = (index: number) => {
    let quantityArray = [];
    for (let i = 0; i < index; i++) {
      quantityArray.push(i + 1);
    }

    return quantityArray.map((quantity) => (
      <p
        key={quantity}
        onClick={() => {
          quantity && setSelectedQuantity(quantity);
        }}
      >
        {quantity}
      </p>
    ));
  };

  return (
    <>
      <div className={styles.product}>
        <div className={styles.productImage}>
          <img src={image} alt={name} />
        </div>
        <div className={styles.productInfo}>
          <p className={styles.header}>{name}</p>
          <p>From Indieclimb</p>
          <div className={styles.color}>
            <p>Color</p>
            <ProductThumbnails
              thumbnails={products}
              activeThumbnailIndex={activeThumbnailIndex}
              onClickThumbnail={(index: number) =>
                setActiveThumbnailIndex(index)
              }
            />
          </div>
          <div className={styles.size}>
            <p>Sizes</p>
            <ProductSizes
              sizes={sizes} // Passed as expected after the update
              activeSizeIndex={activeSizeIndex}
              onClickSize={(index: number) => {
                setActiveSizeIndex(index);
              }}
            />
          </div>
          <div className={styles.orderDetails}>
            <div className={styles.itemOverview}>
              <p className={styles.price}>{price} €</p>
              <div className={styles.quantity}>
                <p>{selectedQuantity}</p>
                <div className={styles.arrowDown}></div>
                <div className={styles.quantityDropdown}>
                  {loopThroughQuantity(
                    products[activeThumbnailIndex].sizes[activeSizeIndex]
                      ?.quantity
                  )}
                </div>
              </div>
              <div></div>
            </div>
            <ColorButton isUppercase link="/cart" text="Add to cart" />
          </div>
        </div>
      </div>

      <div className={styles.productDescription}>
        <p>Product ID: {productId}</p>
        <p>Description: {description}</p>
      </div>
    </>
  );
};
