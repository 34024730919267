import React, { useEffect } from "react";

const ScrollLine = () => {
  useEffect(() => {
    let rafId: any; // Reference to the requestAnimationFrame ID

    const handleScroll = () => {
      const scrollLine = document.getElementById("scrollLine");
      if (scrollLine) {
        // Check if the element exists
        let maxScrollValue = document.body.scrollHeight - window.innerHeight;
        let scrollProgress = (window.scrollY / maxScrollValue) * 100;

        // Use requestAnimationFrame for smoother animation
        if (rafId) {
          cancelAnimationFrame(rafId); // Cancel the previous requestAnimationFrame call
        }
        rafId = requestAnimationFrame(() => {
          scrollLine.style.height = `${scrollProgress}%`;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and cancelAnimationFrame
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "1px",
        height: "100%", // Start with 0% height
        zIndex: 9999,
      }}
      className="scrollLine"
    >
      <div
        id="scrollLine"
        style={{
          height: "0%",
          width: "100%",
          background: "#0000b2",
        }}
      ></div>
    </div>
  );
};

export default ScrollLine;
