import React from "react";
import styles from "./productSizes.module.scss";

// Updated interface to match the new structure of sizes prop
interface Size {
  size: string;
  quantity: number;
}

interface ProductSizesProps {
  sizes: Size[]; // Use the updated Size type here
  activeSizeIndex: number;
  onClickSize: (index: number) => void;
}

export const ProductSizes: React.FC<ProductSizesProps> = ({
  sizes,
  activeSizeIndex,
  onClickSize,
}) => {
  return (
    <div className={styles.productSizes}>
      {sizes.map((sizeObj, index) => (
        <div
          key={index}
          className={`${styles.size} ${
            index === activeSizeIndex ? styles.active : ""
          }`}
          onClick={() => onClickSize(index)}
        >
          {sizeObj.size} {/* Access the size property of the sizeObj */}
        </div>
      ))}
    </div>
  );
};
