import React from "react";
import styles from "./home.module.scss";
import { Hero } from "../../components/Hero/hero.component";
import { Footer } from "../../components/Footer/footer.component";

export const Home = () => {
  return (
    <div className={styles.homePage}>
      <Hero image="/images/photos/hero.jpg" />
      <Footer />
    </div>
  );
};
