import React, { useEffect } from "react";
import styles from "./Newsletter.module.scss";
import { SignupForm } from "../../components/SignupForm/SignupForm.component";
import { useLocation, useNavigate } from "react-router-dom";

export const Newsletter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the pathname is just '/' or effectively empty
    if (location.pathname === "/newsletter") {
      navigate("/en/newsletter");
    }
  }, [navigate, location.pathname]);
  return (
    <div className={styles.newsletter}>
      <SignupForm />
    </div>
  );
};
